import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from '@external/react-i18next';
import { OneColumn } from '@components/Layouts/OneColumn';
import Title from '@components/Title';
import { Button } from '@components/Button';
import { useAppConfig } from '@hooks/appConfig';
import PasswordInstructions from '@components/Pages/ActivateAccount/PasswordInstructions';
import { Formik, Form } from 'formik';
import TextField from '@components/Formik/TextField';
import { getQueryParams } from '@utils/query-params';
import Loading from '@components/Loading';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import {
  isRequired,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';
import { useMutation, gql } from '@apollo/client';
import {
  UpdateResetPasswordMutationVariables,
  UpdateResetPasswordMutation,
  FetchSecurityQuestionMutationVariables,
  FetchSecurityQuestionMutation,
} from '@typings/operations';

const useFetchSecurityQuestionMutation = () =>
  useMutation<
    FetchSecurityQuestionMutation,
    FetchSecurityQuestionMutationVariables
  >(gql`
    mutation fetchSecurityQuestion($token: String!) {
      accountInfoByToken(token: $token) {
        currentSecurityQuestion
      }
    }
  `);

const useUpdateResetPasswordMutation = () =>
  useMutation<
    UpdateResetPasswordMutation,
    UpdateResetPasswordMutationVariables
  >(gql`
    mutation updateResetPassword(
      $token: String!
      $password: String!
      $answer: String!
    ) {
      updatePassword(token: $token, password: $password, answer: $answer)
    }
  `);

interface FormValues {
  answer: string;
  newPassword: string;
  confirmNewPassword: string;
}

const formInitialValues: FormValues = {
  answer: '',
  newPassword: '',
  confirmNewPassword: '',
};

const buildValidationSchema = (t: TFunction) =>
  yup.object().shape({
    answer: schemaField(t, yup.string(), isRequired),
    newPassword: schemaField(t, yup.string(), isRequired),
    confirmNewPassword: schemaField(t, yup.string(), isRequired).oneOf(
      [yup.ref('newPassword'), null],
      t(
        'form.field.confirm_does_not_match_password',
        'Your passwords do not match'
      )
    ),
  });

interface Props extends RouteComponentProps {}
const ConfirmResetPassword: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { login } = useAppConfig();
  const { token } = getQueryParams();
  const [showSuccess, setShowSuccess] = useState(false);
  const [
    fetchSecurityQuestion,
    { loading, data },
  ] = useFetchSecurityQuestionMutation();
  const [updateResetPassword] = useUpdateResetPasswordMutation();

  useEffect(() => {
    if (typeof token === 'string') {
      fetchSecurityQuestion({ variables: { token } });
    }
  }, [token, fetchSecurityQuestion]);

  if (loading || !data?.accountInfoByToken || !token) {
    return <Loading />;
  }

  const { currentSecurityQuestion } = data.accountInfoByToken;

  const handleSubmit = ({ answer, newPassword }: FormValues) => {
    if (typeof token !== 'string') {
      throw new Error('Token must be a string.');
    }
    const variables = {
      answer,
      password: newPassword,
      token,
    };
    updateResetPassword({ variables }).then(() => setShowSuccess(true));
  };
  return (
    <OneColumn>
      <Title>{t('confirm-reset-password.title', 'Create New Password')}</Title>
      {showSuccess ? (
        <>
          <p>
            {t(
              'confirm-reset-password.success',
              'Your new password has been saved. You may now sign in to your account'
            )}
          </p>
          <Button clickHandler={() => login(false)}>
            {t(
              'confirm-reset-password.success.button-label',
              'Continue to login'
            )}
          </Button>
        </>
      ) : (
        <>
          <PasswordInstructions />
          <h3 className="h5">
            {t('confirm-reset-password.form.answer-label', 'Security Question')}
          </h3>
          <p>{currentSecurityQuestion}</p>
          <Formik
            initialValues={formInitialValues}
            onSubmit={handleSubmit}
            validationSchema={buildValidationSchema(t)}
          >
            {({ isSubmitting }) => (
              <Form>
                <TextField
                  name="answer"
                  label={t(
                    'confirm-reset-password.form.answer-label',
                    'Answer'
                  )}
                  required
                />
                <TextField
                  name="newPassword"
                  label={t(
                    'confirm-reset-password.form.newPassword-label',
                    'New Password'
                  )}
                  password
                  required
                />
                <TextField
                  name="confirmNewPassword"
                  label={t(
                    'confirm-reset-password.form.confirmNewPassword-label',
                    'Confirm New Password'
                  )}
                  password
                  required
                />
                <Button disabled={isSubmitting}>
                  {t('confirm-reset-password.form.submit-label', 'Submit')}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </OneColumn>
  );
};

export default ConfirmResetPassword;

import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import Icon from '../Icon';
import Element, { ElementProps } from './Element';

import { useTranslation } from '@external/react-i18next';

export interface TextFieldProps extends ElementProps {
  /**
   * Placeholder text to be displayed when empty.
   */
  placeholder?: string;
  /**
   * Display a reset-icon at the right.
   */
  resettable?: boolean;
  /**
   * Display an icon in the left end of the element.
   */
  icon?: 'magnifier';
  /**
   * Sets input type as password
   */
  password?: boolean;
  /**
   * Sets input type
   */
  type?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  extraClasses?: string;
  onBlurHandler?: () => void;
  LeadsForms?: boolean;
  onEnterHandler?: () => void;
}

/**
 * Text field component. Allows displaying icons on the left and option
 * reset-button on the right.
 */
const TextField: React.FC<TextFieldProps> = props => {
  const {
    name,
    icon,
    disabled,
    placeholder,
    type,
    password,
    resettable,
    inputRef,
    label,
    extraClasses,
    onBlurHandler,
    LeadsForms,
    onEnterHandler,
  } = props;
  const [field, meta, helpers] = useField<string>({
    name,
    defaultValue: '',
  });
  const { t } = useTranslation();
  const emailForm = LeadsForms ? null : { onBlur: onBlurHandler };
  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter' && onEnterHandler) {
      onEnterHandler();
    }
  };

  return (
    <Element {...props}>
      {icon === 'magnifier' && (
        <span className="absolute-vertical-center absolute left-0 ml-3 pointer-events-none">
          <Icon
            name="ui/search"
            size="16"
            color={disabled ? 'gray-300' : 'bright-blue-600'}
          />
        </span>
      )}

      <input
        id={name}
        disabled={disabled}
        placeholder={placeholder}
        {...field}
        type={type || (password ? 'password' : 'text')}
        className={classNames(
          'border border-gray-300 focus:border-dark-blue-400 rounded-sm',
          'w-full py-3 px-4 text-xs leading-loose focus-visible:outline-0',
          'focus:shadow-none',
          extraClasses,
          {
            'pl-10': icon,
            'pr-10': resettable,
            'bg-gray-100 cursor-not-allowed placeholder-gray-300 opacity-100 text-gray-600': disabled,
            'placeholder-gray-400': !disabled,
            'bg-red-100 border-red-600': !!meta.error && meta.touched,
          }
        )}
        ref={inputRef}
        {...(name === 'email' ? emailForm : null)}
        onKeyPress={handleKeyPress}
      />

      {resettable && field.value && (
        <button
          type="button"
          onClick={() => helpers.setValue('')}
          className="absolute-vertical-center absolute z-0 right-0 mr-2 cursor-pointer"
          data-element-type="clear-input"
        >
          <span className="sr-only">
            {t('forms.text-field.reset-button', 'Reset “{{label}}”', {
              label,
            })}
          </span>
          <Icon name="circles/close" color="gray-300" />
        </button>
      )}
    </Element>
  );
};

export default TextField;

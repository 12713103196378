import React from 'react';
import { useAppConfig } from '@hooks/appConfig';
import Loading from '@components/Loading';
import { localizedNavigate } from '@utils/localized-navigate';

const OnlyAnonymous: React.FC<{ redirectTo: string }> = ({
  redirectTo,
  children,
}) => {
  const { user } = useAppConfig();

  if (user?.isLoggedIn) {
    localizedNavigate(redirectTo);
    return <Loading />;
  }
  return <>{children}</>;
};

export default OnlyAnonymous;

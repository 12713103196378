import React from 'react';

import { useTranslation } from '@external/react-i18next';

const PasswordInstructions = () => {
  const { t } = useTranslation();
  return (
    <>
      <p className="text-big mb-2">
        {t('activate-account.password-instructions.title', 'Your password:')}
      </p>
      <ul className="list-disc text-big mb-10">
        <li>
          {t(
            'activate-account.password-instructions.rule1',
            'Must be at least 8 characters'
          )}
        </li>
        <li>
          {t(
            'activate-account.password-instructions.rule2',
            'Must contain 1 lowercase letter'
          )}
        </li>
        <li>
          {t(
            'activate-account.password-instructions.rule5',
            'Must contain 1 uppercase letter'
          )}
        </li>
        <li>
          {t(
            'activate-account.password-instructions.rule3',
            'Must contain 1 number'
          )}
        </li>
        <li>
          {t(
            'activate-account.password-instructions.rule4',
            'May not include any part of your email address'
          )}
        </li>
      </ul>
      <p className="text-big mb-2">
        {t(
          'activate-account.security-answers-instructions.title',
          'The answer to your security question:'
        )}
      </p>
      <ul className="list-disc text-big mb-10">
        <li>
          {t(
            'activate-account.security-answers-instructions.rule1',
            'Must be at least 4 characters'
          )}
        </li>
        <li>
          {t(
            'activate-account.security-answers-instructions.rule2',
            'May not include your full password'
          )}
        </li>
        <li>
          {t(
            'activate-account.security-answers-instructions.rule3',
            'May not include any part of your security question'
          )}
        </li>
        <li>
          {t(
            'activate-account.security-answers-instructions.rule4',
            'May not include your full email address'
          )}
        </li>
      </ul>
    </>
  );
};

export default PasswordInstructions;

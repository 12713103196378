import React from 'react';
import ConfirmResetPassword from '@components/Pages/Account/ResetPassword/ConfirmResetPassword';
import OnlyAnonymous from '@components/Auth/OnlyAnonymous';

const ConfirmResetPasswordPage = () => (
  <OnlyAnonymous redirectTo={'/account/settings'}>
    <ConfirmResetPassword />
  </OnlyAnonymous>
);
export default ConfirmResetPasswordPage;
